<template>
  <div v-if="false" id="basic-info">
    <div>
      <label>Order id:</label>
      <p class="value">{{ order.id }}</p>
    </div>
    <div>
      <label>Create Date:</label>
      <p class="value">{{ order.createdAt }}</p>
    </div>
    <div>
      <label>Update Date:</label>
      <p class="value">{{ order.updatedAt }}</p>
    </div>
<!--    <div>-->
<!--      <label>Status:</label>-->
<!--      <p class="value">{{ orderStatus[order.quantity] }}</p>-->
<!--    </div>-->
<!--    <div>-->
<!--      <label>Payment status:</label>-->
<!--      <p class="value">{{ paymentStatus[order.paymentStatus] }}</p>-->
<!--    </div>-->
    <div>
      <label>Quantity:</label>
      <p class="value">{{ order.quantity }}</p>
    </div>
    <div>
      <label>Revenue:</label>
      <p class="value">{{ order.revenue }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDetails",
  props: {
    order: {
      type: Object,
      default: () => {
      },
    }
  },
  data() {
    return {
      paymentStatus: {
        0: "Waiting",
        1: "Failed",
        2: "Paid",
        3: "Refunded",
        4: "PartiallyRefunded"
      },
      orderStatus: {
        0: 'Unknown',
        1: 'Pending',
        2: 'Finished',
        3: 'SeenByReceiver',
        4: 'Rejected',
        5: 'Scheduled',
      },
    }
  }
}
</script>

<style scoped>
label {
  font-weight: 300;
  margin-bottom: 0;
  color: gray;
}
#basic-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}
</style>
