<template>
  <div>
    <b-table
      sticky-header
      bordered
      hover
      size="sm"
      responsive
      class="shadow-sm rounded products-table orders-table"
      thead-tr-class="order-main-thead"
      :items="items"
      :fields="fields"
      :busy="isTableBusy"
      primary-key="id"
      show-empty
      :empty-text="$t('global.no-items')"
    >
      <template #cell(status)="data">
        <div class="d-flex align-items-center">
          <b-form-select
            v-model="data.item.status"
            value-field="value"
            text-field="text"
            class="mr-1"
            size="sm"
            :options="computedOptions(data.item.statusesCanSet)"
          />
          <b-button
            size="sm"
            variant="success"
            :disabled="
              !data.item.statusesCanSet ||
              !data.item.statusesCanSet.includes(data.item.status)
            "
            @click="changeStatus(data.item)"
          >
            <feather-icon size="10" icon="SaveIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BButton, BFormSelect } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "OrderItemsTable",
  components: {
    BTable,
    BButton,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedStatus: {
      type: [String, Number],
      default: null,
    },
    refetchMethod: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState("ordersModule", ["isTableBusy"]),
    orderItemStatus() {
      return [
        { value: 0, text: this.$i18n.t("orders.booked"), disabled: false },
        { value: 1, text: this.$i18n.t("orders.preOrdered"), disabled: false },
        {
          value: 2,
          text: this.$i18n.t("orders.seenBySeller"),
          disabled: false,
        },
        {
          value: 3,
          text: this.$i18n.t("orders.acceptedBySeller"),
          disabled: false,
        },
        {
          value: 4,
          text: this.$i18n.t("orders.cancelledBySeller"),
          disabled: false,
        },
        { value: 5, text: this.$i18n.t("orders.scheduled"), disabled: false },
        {
          value: 6,
          text: this.$i18n.t("orders.acceptedByReceiver"),
          disabled: false,
        },
        {
          value: 7,
          text: this.$i18n.t("orders.rejectedByReceiver"),
          disabled: false,
        },
        {
          value: 8,
          text: this.$i18n.t("orders.orderedConfirmed"),
          disabled: false,
        },
        { value: 9, text: this.$i18n.t("orders.shipped"), disabled: false },
        { value: 10, text: this.$i18n.t("orders.delivered"), disabled: false },
        { value: 11, text: this.$i18n.t("orders.finished"), disabled: false },
        { value: 12, text: this.$i18n.t("orders.failed"), disabled: false },
      ];
    },
    fields() {
      return [
        {
          label: this.$t("orders.status"),
          key: "status",
          formatter: (value) => this.orderItemStatus[value],
          class: "status-field",
        },
        {
          label: this.$t("orders.id"),
          key: "orderId",
        },
        {
          label: this.$t("orders.child-id"),
          key: "id",
        },
        {
          label: this.$t("products.name"),
          key: "nameGeo",
          sortable: false,
          formatter: (value, key, item) =>
            `${item.product.nameGeo}  / ${item.product.nameEng}`,
        },
        {
          label: this.$t("orders.p-id"),
          key: "p-id",
          formatter: (value, key, item) => item.product.id,
        },
        {
          label: this.$t("products.comission"),
          key: "commissionRate",
          formatter: (value) => `${value} %`,
        },
        {
          label: this.$t("orders.revenue"),
          key: "amount",
          formatter: (value, key, item) => `${item.amount.toFixed(2)} ₾`,
        },
        {
          label: this.$t("orders.deliveryAddress"),
          key: "deliveryAddress",
        },
      ];
    },
  },
  methods: {
    ...mapActions("ordersModule", ["updateOrderStatus"]),
    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
    computedOptions(allowedStatuses) {
      if (!allowedStatuses) {
        return this.orderItemStatus.map((status) => ({
          ...status,
          disabled: true,
        }));
      }

      return this.orderItemStatus.map((status) => ({
        ...status,
        disabled: !allowedStatuses.includes(status.value),
      }));
    },
    changeStatus(item) {
      const payload = {
        orderId: item.orderId,
        orderItemId: item.id,
        statusObject: {
          status: parseFloat(item.status),
        },
      };
      this.updateOrderStatus(payload).then((res) => {
        if (res.data.error) {
          this.showToast(res.data.error.message, "danger");
        } else {
          this.showToast("Status Changed", "success");
          this.refetchMethod();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
label {
  font-weight: 300;
  margin-bottom: 0;
  color: gray;
}

.b-table-details {
  padding: 0 !important;
}

#basic-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  div {
    margin: 0 1rem 1rem 0;
  }
}
</style>
