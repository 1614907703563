<template>
  <b-card
    class="relative"
    :header="$t('orders.details')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <div>
      <b-button
        size="sm"
        class="ml-auto cursor-pointer mb-2"
        variant="outline-primary"
        style="display: block"
        @click="$router.push('/orders')"
      >
        <feather-icon icon="ArrowLeftCircleIcon" class="mr-25" />
        {{ $t("global.go-back") }}
      </b-button>
    </div>
    <OrderDetails v-if="order" :order="order" />
    <OrderItemsTable
      v-if="order"
      :items="order.items"
      :refetch-method="getSingleOrder"
    />
  </b-card>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import OrderDetails from "@/views/order-management/Components/OrderDetails.vue";
import OrderItemsTable from "@/views/order-management/Components/OrderItemsTable.vue";

export default {
  name: "SingleOrder",
  components: {
    BCard,
    OrderDetails,
    OrderItemsTable,
    BButton,
  },
  data() {
    return {
      orderId: null,
    };
  },
  computed: {
    ...mapState("ordersModule", ["order"]),
  },
  created() {
    this.orderId = router.currentRoute.params.id;
    this.getSingleOrder();
  },
  methods: {
    ...mapActions("ordersModule", ["getOrderById"]),
    getSingleOrder() {
      this.getOrderById(this.orderId).then(() => console.log(this.order));
    },
  },
};
</script>

<style scoped></style>
